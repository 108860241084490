import { Entity } from './common';
import { User } from './user';

export enum MatchStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN-PROGRESS',
  FINISHED = 'FINISHED',
}

export enum RoundTrip {
  HOME = 'HOME',
  AWAY = 'AWAY',
}

export enum MatchDateStatus {
  PERMUTED = 'PERMUTED',
  POSTPONED = 'POSTPONED',
  ADVANCED = 'ADVANCED',
}

export interface Match extends Entity {
  seasonId: string;
  round: number; // Numero de jornada en liga. Numero de ronda en copa.
  match?: number; // Numero de partido en copa de una ronda.
  group?: number;
  roundTrip?: RoundTrip | null;
  localTeamId: string | null;
  visitorTeamId: string | null;
  refereeId?: string;
  date?: Date;
  officialDate?: Date;
  rest: boolean;
  games?: Game[];
  status: MatchStatus;
  winTeamId?: string;
  incident?: string;

  dateStatus?: {
    type: MatchDateStatus;
  };

  result?: {
    local: number;
    visitor: number;
  };

  localAlignment?: PlayerPosition[];
  localSubstitutes?: string[];
  localSummoned?: string[];

  visitorAlignment?: PlayerPosition[];
  visitorSubstitutes?: string[];
  visitorSummoned?: string[];
  notes?: string;
  seasonYear: string; // Year
}

export interface Game {
  sets: Set[];
  localTeam: PlayerPosition;
  visitorTeam: PlayerPosition;
  substitutePlayers: Substitute[];
  timeouts: {
    team: 'local' | 'visitor';
    set: 1 | 2 | 3;
  }[];
  usedBalls: number;
  brokenBalls: number;
}

export interface PlayerPosition {
  back?: string;
  front?: string;
}

export interface Set {
  local: number;
  visitor: number;
}

export interface Substitute {
  in: string;
  out: string;
}

export interface Journey {
  id: string;
  seasonId: string;
  round: number;
  teamIds: string[];
  summonedAlignments: Record<string, string[]>;
  confirmedAlignments: Record<string, Partial<{ back: string; front: string; substitute: string } | undefined>[]>;
  startDate: Date;
  endDate: Date;
  date: Date;
  calculated: boolean;
}

export interface JEConfirmationData {
  players?: Partial<{ back: User; front: User; substitute: User } | undefined>[];
  teamId: string;
  journeyId: string;
  seasonId: string;
}
export interface JEDeleteConfirmationData {
  teamId?: string;
  journeyId?: string;
  seasonId?: string;
}
